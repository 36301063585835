/* reset */
button {
  all: unset;
}

.selectRoot {
  height: var(--number-box-height);
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  min-width: 60px;
  /* color: var(--violet-11); */
  color: var(--number-box-font-color);
  /* box-shadow: 0 2px 10px var(--black-a7); */
  border: 1px solid var(--number-box-border-color);
  margin-left: 10px;
}
.SelectTrigger:hover {
  background-color: var(--mauve-3);
}
.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  /* color: var(--violet-9); */
  color: var(--number-box-font-color);
}

.SelectIcon {
  /* color: Var(--violet-11); */
  color: var(--number-box-font-color);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  /* color: var(--violet-11); */
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  /* background-color: var(--violet-9); */
  /* color: var(--violet-1); */
  background-color: var(--hover-button-color);
  color: white;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}
