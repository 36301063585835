const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_yhdxywO4A',
  aws_user_pools_web_client_id: '7n98lj1fda2au0rvmb6hg4ucau',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
